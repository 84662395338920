import classNames from 'classnames'
import { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type TertiaryProps = Pick<
  LinkProps,
  'to' | 'target' | 'rel' | 'title' | 'children' | 'className' | 'replace'
>

type ButtonProps = TertiaryProps & { size?: 'sm' | 'md' | 'lg' }

type PrimaryProps = ButtonProps & {
  color?: 'blue' | 'orange'
}

const buttonClasses = {
  base: 'inline-block border font-normal rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
  primary: (color: PrimaryProps['color']) =>
    classNames(
      'text-white border-transparent',
      color === 'orange' &&
        'bg-orange-500 border-orange-600 hover:bg-orange-700 hover:border-orange-900 focus:ring-orange-500',
      color === 'blue' &&
        'bg-blue-600 border-blue-700 hover:bg-blue-700 hover:border-blue-800 focus:ring-blue-500'
    ),
  secondary: 'text-gray-700 border-gray-300 bg-white hover:bg-gray-50 focus:ring-yellow-500',
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base',
}

const Tertiary = forwardRef<HTMLAnchorElement, TertiaryProps>(
  ({ children, className, ...props }, ref) => (
    <Link
      {...props}
      className={twMerge('text-blue-500 underline underline-offset-2', className)}
      ref={ref}
    >
      {children}
    </Link>
  )
)

const Secondary = forwardRef<HTMLAnchorElement, ButtonProps>(
  ({ children, size = 'md', className, ...props }, ref) => (
    <Link
      {...props}
      className={twMerge(
        `${buttonClasses.base} ${buttonClasses.secondary} ${buttonClasses[size]}`,
        className
      )}
      ref={ref}
    >
      {children}
    </Link>
  )
)

const Primary = forwardRef<HTMLAnchorElement, PrimaryProps>(
  ({ children, size = 'md', className, color = 'orange', ...props }, ref) => (
    <Link
      {...props}
      className={twMerge(
        `${buttonClasses.base} ${buttonClasses.primary(color)} ${buttonClasses[size]}`,
        className
      )}
      ref={ref}
    >
      {children}
    </Link>
  )
)

export default { T: Tertiary, S: Secondary, P: Primary }
